const defaultFonts = [
  'Lato-Light.woff2',
  'Lato-Regular.woff2',
  'Lato-Italic.woff2',
  'Lato-Medium.woff2',
  'Lato-Semibold.woff2',
  'Lato-Bold.woff2',
];

const nanumFonts = ['NanumSquareR.woff2', 'NanumSquareB.woff2'];

export const loadDefaultFonts = () => {
  defaultFonts.forEach((font, index) => {
    let elHead, elLink;

    elLink = document.createElement('link');
    elLink.rel = 'preload';
    elLink.as = 'font';
    elLink.type = 'font/woff2';
    elLink.href = `./fonts/${defaultFonts[index]}`;
    elLink.crossOrigin = 'anonymous';

    elHead = document.getElementsByTagName('head')[0];
    elHead.appendChild(elLink);
  });
  let elBody = document.getElementsByTagName('body')[0];
  elBody.style.setProperty('--default-font-family', 'Lato');
};

export const loadKrFonts = () => {
  nanumFonts.forEach((font, index) => {
    let elHead, elLink, elBody;

    elLink = document.createElement('link');
    elLink.rel = 'preload';
    elLink.as = 'font';
    elLink.type = 'font/woff2';
    elLink.href = `./fonts/${nanumFonts[index]}`;
    elLink.crossOrigin = 'anonymous';

    elHead = document.getElementsByTagName('head')[0];
    elHead.appendChild(elLink);
  });
  let elBody = document.getElementsByTagName('body')[0];
  elBody.style.setProperty('--default-font-family', 'Nanum');
  elBody.setAttribute('class', 'word-break-keep-all');
};

export const getFontsByCountry = () => {
  const browserLanguage = navigator.language;
  if (browserLanguage === 'ko') {
    loadKrFonts();
  } else {
    loadDefaultFonts();
  }
};
